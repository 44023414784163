import { BrandButton, Heading } from '@okam/brand-ui/index'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'

import { systemConfig } from '@/features/system/system.config'

type Props = {
  title?: string
  children?: never
}

export const NotFoundPage = ({ title }: Props) => {
  const { t } = useTranslation(systemConfig.i18nNamespaces)
  const pageTitle = title ?? t('system:notFound.title')
  const linkToHomeTitle = t('system:links.backToHome')
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div tw="flex h-screen w-screen flex-col items-center justify-center bg-tertiary-1">
        <Heading as="h1" variant="h1" tw="font-bold text-tertiary-5">
          {pageTitle}
        </Heading>
        <BrandButton as="a" variant="black" href="/" buttonStyle="outline">
          {linkToHomeTitle}
        </BrandButton>
      </div>
    </>
  )
}
